.sq_set[data-v-46c62b97] {
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  color: var(--themeColor, #17a2b8);
  display: block;
}
.iconfont[data-v-46c62b97] {
  margin-right: 4px;
  font-size: 16px;
}
.certificate[data-v-46c62b97] {
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  margin-left: 109px;
  cursor: pointer;
  display: block;
  margin-top: -17px;
}